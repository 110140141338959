<!-- 微信支付 -->
<template>
    <div>
        <el-dialog title="微信支付" width="675px" :visible.sync="show" :close-on-click-modal="false" 
            :close-on-press-escape="false">
            <div class="flex justify-c mb-20">
                <div class="flex-column align-c">
                    <div id="qrcode" class="qrcode-wrapper"></div>
                    <div class="flex justify-c align-c qrcode-meta">
                        <div class="qrcode-icon"></div>
                        <div>
                            <p>请使用微信扫一扫</p>
                            <p class="mt-5">扫描二维码支付</p>
                        </div>
                    </div>    
                </div>
                <div class="qrcode-r flex-column">
                    <div class="flex color-666 qrcode-tips">
                        <span class="mr-10">提示：</span>
                        <div class="flex-1">
                            <p>1、二维码若已过期，请点击<span class="color-orange">刷新二维码</span>按钮重新获取二维码；</p>
                            <p class="mt-10">2、支付完成后请点击<span class="color-orange">支付完成</span>按钮。</p>
                        </div>
                    </div>
                    <div class="flex-column flex-1 justify-c align-c">
                        <div class="qrcode-btn" @click="refreshQrcodeFn">刷新二维码</div>
                        <div class="qrcode-btn" @click="checkOrderFn">支付完成</div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
    name: 'WxPay',
    components: {},

    props:{
        isWxPay: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        isWxPay() {
            this.show = this.isWxPay
        },

        show() {
            this.$emit('visibleChange', this.show)
        }
    },

    data() {
        return {
           show: false, 
           payId: null,
           couponId: null,
           scorchCount: 0
        };
    },

    created() {
        
    },

    methods: {
        init(qrcode, payId, couponId, scorchCount) {
            this.payId = payId
            this.couponId = couponId
            this.scorchCount = scorchCount
            this.$nextTick(() => {
                this.createQrCode(qrcode)
            })
        },

        //创建二维码
        createQrCode(qrcode) {
            document.getElementById("qrcode").innerHTML = ""; // 清空之前生成的二维码内容
            let qrCode = new QRCode("qrcode", {
                width: 200, // 设置宽度，单位像素
                height: 200, // 设置高度，单位像素
                text: qrcode, // 设置二维码内容(必须是字符串)
                render: "canvas"
            })
        },

        //重新获取二维码
        refreshQrcodeFn() {
            this.$https.post(this.$api.apppay,{
                payType: 1,
                orderId: this.payId,
                couponId: this.couponId,//优惠卷id
                points: this.scorchCount || ''//积分抵扣
            })
            .then((res) =>{
                let qrcode = res.data.package.replace('code_url=', '')
                this.$nextTick(() => {
                    this.createQrCode(qrcode)
                    this.$message({
                        message: '二维码已刷新',
                        type: 'success'
                    });
                })
            })
            
        },

        //验证订单是否支付完成
        checkOrderFn() {
            this.$https.get(this.$api.orderInfo,{
                orderId: this.payId,
                id: '',
                iskuaidi:'',
                shopId: '',
                number: Math.random()
            })
            .then((res) =>{
                let status = res.data.status
                if(status == -1){
                    this.$msgbox.alert('订单超时未支付已被关闭，请重新提交订单', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    }).then(() =>{
                        this.show = false
                    })
                } else if(status == 0) {
                    this.$msgbox.alert('请先扫描二维码完成支付，再点击支付完成按钮哦~', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                } else if(status == 1){
                    this.$router.push({
                        name: 'payResult',
                        query: {
                            orderId: this.payId
                        }
                    })
                }
            })
            
        }
    }
}
</script>
<style lang='scss' scoped>
    .qrcode-wrapper{
        padding: 20px;
        border: 1px solid #dedede;
    }
    .qrcode-meta{
        width: 241px;
        padding: 7px;
        margin-top: 20px;
        color: #fff;
        background-color: #f79a65;
        .qrcode-icon{
            width: 44px;
            height: 44px;
            margin-right: 15px;
            background: url(../../assets/images/sprite.png) no-repeat;
            background-position: 0 -164px;
        }
    }
    .qrcode-r{
        margin-left: 50px;
        .qrcode-tips{
            width: 250px;
            font-size: 12px;
            line-height: 1.6;
        }
        .qrcode-btn{
            width: 150px;
            height: 42px;
            margin-bottom: 30px;
            color: #fff;
            line-height: 42px;
            text-align: center;
            border-radius: 3px;
            cursor: pointer;
            background-color: var(--orange-color);
            &:last-child{
                margin-right: 0;
            }
        }
    } 
</style>